<template>
<div class="container padding-container">
    <el-button class="topBtns" type="primary" icon="el-icon-plus" @click="handleEditAccountShow()">新增账号</el-button>
    <div class="border-container">
        <el-row class="info-container" type="flex" align="middle" justify="space-between">
            <div class="list-title">
                <span>学校账号</span>
            </div>
        </el-row>
        <div class="table-container">
            <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" size="medium"
                :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                :cell-style="{height: '50px',padding: '5px 0'}" header-align="center">
                <el-table-column prop="name" label="用户名"  min-width="155" :show-overflow-tooltip="true"  align="center">
                    <template slot-scope="scope">
                        {{scope.row.user ? scope.row.user.name : '-'}}
                    </template>
                </el-table-column>
                <el-table-column prop="phone" label="手机号" min-width="155" align="center">
                    <template slot-scope="scope">
                        {{scope.row.user ? scope.row.user.phone : '-'}}
                    </template>
                </el-table-column>
                <el-table-column prop="username" label="角色" min-width="120" align="center">
                    <template slot-scope="scope">
                        {{scope.row.role_name || '-'}}
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" label="创建时间" min-width="155" align="center">
                    <template slot-scope="scope">
                        {{scope.row.user ? timeFilterFn(scope.row.user.create_time)  : '-'}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="155" align="center" v-if="auth('MANAGER')">
                    <template slot-scope="scope">
                        <el-button type="text" class="icon-btn" @click="handleEditAccountShow(scope.row)">
                            <el-tooltip placement="top" content="修改用户">
                                <i class="iconfont icon-edit"></i>
                            </el-tooltip>
                        </el-button>
                        <el-button type="text" class="icon-btn" @click="handlePasswordShow(scope.row)">
                            <el-tooltip placement="top" content="修改用户密码">
                                <i class="iconfont icon-lock"></i>
                            </el-tooltip>
                        </el-button>
                        <el-button type="text" class="icon-btn" @click="handleDeleteAccount(scope.row)">
                            <el-tooltip placement="top" content="删除账号">
                                <i class="iconfont icon-delete"></i>
                            </el-tooltip>
                        </el-button>
                    </template>
                </el-table-column>
                <div class="" slot="empty">
                    <no-data></no-data>
                </div>
            </el-table>
        </div>
        <div class="page-container">
            <el-pagination class="right" layout="total, sizes, prev, pager, next" background
                @size-change="pageSizeChange"
                @current-change="pageChange"
                :current-page="currentPage"
                :page-sizes="[total, 10, 15, 20, 25]"
                :total="total" :page-size="pageSize">
            </el-pagination>
        </div>
    </div>
    <template class="dialog-container">
        <el-dialog :title="editTitle" :visible.sync="editVisible" class="school-account-dialog"
            width="600px" @close="handleEditAccountClose" :close-on-click-modal="editType === 'new' ? false : true">
            <div class="dialog-content">
                <div class="content-floor" v-if="auth('MANAGER')">
                    <span class="key">用户角色</span>
                    <el-select class="value" v-model="editForm.role_id" placeholder="请选择账号角色">
                        <el-option v-for="item of roleList" :label="item.name" :value="item.id" :key="item.id" />
                    </el-select>
                </div>
                <div class="content-floor">
                    <span class="key">用户名</span>
                    <el-input class="value" v-model="editForm.name" placeholder="请输入用户名" />
                </div>
                <div class="content-floor" v-if="editType === 'new'">
                    <span class="key">手机号</span>
                    <el-input class="value" v-model="editForm.phone" placeholder="请输入手机号" />
                </div>
                <div class="content-floor" v-if="editType === 'new'">
                    <span class="key">密码</span>
                    <el-input class="value" v-model="editForm.password" placeholder="请输入密码"
                        type="password" show-password />
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleEditAccount">保存</el-button>
                <el-button class="cancel" @click="editVisible = false">取消</el-button>
            </div>
        </el-dialog>

        <el-dialog title="修改密码" :visible.sync="passwordVisible" class="school-account-dialog"
            width="600px" @closed="handlePasswordClose">
            <div class="dialog-content">
                <div class="content-floor">
                    <span class="key">密码</span>
                    <el-input class="value" v-model="passwordForm.newPass" placeholder="请输入新密码" type="password" show-password />
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handlePasswordChange">保存</el-button>
                <el-button class="cancel" @click="passwordVisible = false">取消</el-button>
            </div>
        </el-dialog>
    </template>
</div>
</template>

<script>
import Core from '@/core';
import { timeFilter } from '@/core/filters/index.js'
export default {
    components: {
        NoData: () => import('@/components/Empty.vue'),
    },
    props: {},
    data() {
        return {
            total: 0,
            currentPage: 1,
            pageSize: 10,

            tableData: [],

            editVisible: false,
            editType: '',
            editTitle: '',
            editForm: {
                id: 0,
                password: '',
                phone: '',
                role_id: '',
                type: '',
                name: '',
            },
            roleList: [],

            passwordVisible: false,
            passwordForm: {
                id: '',
                newPass: '',
            }
        };
    },
    computed: {
    },
    created() {},
    mounted() {
        this.getTableData()
    },
    methods: {
        auth: Core.Utils.auth,
        pageChange(page) {
            this.currentPage = page;
            this.getTableData();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getTableData();
        },

        getTableData() {
            Core.Api.SchoolAccount.list(
                '',
                this.currentPage,
                this.pageSize
            ).then(res => {
                console.log("getTableData -> res", res);
                this.total = res.count;
                this.tableData = res.list;
            });
        },
        getRoleList() {
            Core.Api.Authority.list().then(res => {
                console.log("getRoleList -> res", res)
                this.roleList = res.list
            })
        },
        // 新增、修改 账号
        handleEditAccountShow(item) {
            if (item) {
                this.editType = 'edit';
                this.editTitle = '修改账号';
                this.editForm = {
                    id: item.id,
                    password: item.user ? item.user.password : '',
                    phone: item.user ? item.user.phone : '',
                    role_id: item.role_id  || '',
                    type: item.type  || '',
                    name: item.user ? item.user.name : '',
                }
            } else {
                this.editType = 'new';
                this.editTitle = '新增账号';
            }
            this.getRoleList();
            this.editVisible = true;
        },
        handleEditAccountClose() {
            this.editVisible = false;
            Object.assign(this.$data.editForm, this.$options.data().editForm)
        },
        handleEditAccount() {
            console.log("handleEditAccount -> this.editForm", this.editForm);
            if(!this.editForm.name){
                return this.$message.error("请填写用户名")
            }
            if (this.editType === 'new') {
                if(!this.editForm.phone){
                    return this.$message.error("请填写手机号")
                }
                if(!(/^1[3456789]\d{9}$/.test(this.editForm.phone))){
                    return this.$message.error("手机号码有误，请正确填写")
                }
                if(!this.editForm.password){
                    return this.$message.error("请填写密码")
                }
            }
            Core.Api.SchoolAccount.save(
                this.editForm.id,
                this.editForm.password,
                this.editForm.phone,
                '',
                this.editForm.role_id,
                this.editForm.type,
                this.editForm.name
            ).then((res) => {
                this.$message.success('保存成功');
                this.handleEditAccountClose();
                this.getTableData()
            })
        },
        // 修改 账号 密码
        handlePasswordShow(item) {
            this.passwordForm.id = item.id
            this.passwordVisible = true
        },
        handlePasswordClose() {
            this.passwordVisible = false
            this.passwordForm = {
                id: '',
                newPass: '',
            };
        },
        handlePasswordChange() {
            if (this.passwordForm.newPass === '') {
                return this.$message.warning('密码不能为空！')
            }
            Core.Api.SchoolAccount.passwordUpdate(
                this.passwordForm.id,
                this.passwordForm.newPass,
            ).then(res => {
                this.$message.success('修改成功')
                this.handlePasswordClose();
                this.getTableData();
            })
        },
        // 删除 账号
        handleDeleteAccount(item) {
            this.$confirm('确定要删除此账号吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.Api.SchoolAccount.delete(item.id, item.user_id).then(res => {
                    this.$message.success('删除成功!');
                    this.pageChange(1);
                });
            });
        },
        timeFilterFn(val, type = 1) {
            return timeFilter(val, type)
        }
    }
};
</script>

<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
    .input-select {
        width: 100%;
    }
}
.school-account-dialog {
    .dialog-content {
        .content-floor {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0px;
            }
            .key {
                display: inline-block;
                width: 100px;
            }
            .value {
                width: calc(100% - 100px);
                &.el-select {
                    // width: 100%;
                }
            }
        }
    }
    .dialog-footer {
        display: flex;
        justify-content: center;
    }
}
</style>
